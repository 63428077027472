<template>
  <NuxtLinkLocale href="/" v-if="logo">
    <!-- Mobile Logo -->
    <NuxtImg
        loading="lazy"
        format="webp"
        :quality="95"
        fit="outside"
        :src="getImage(logoForMobile)"
        width="200"
        height="75"
        alt=""
        class="lb-logo"
        aria-label="Logo"
        v-if="isMobileOrTablet && isMobileLogoSeparate && logoForMobile"
    />

    <!-- Main Logo -->
    <NuxtImg
        loading="lazy"
        format="webp"
        :quality="95"
        fit="outside"
        :src="getImage(logo)"
        width="200"
        height="75"
        alt=""
        class="lb-logo"
        aria-label="Logo"
    />
  </NuxtLinkLocale>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { logo, logoForMobile, isMobileLogoSeparate } = storeToRefs(storeConfig)
const {isMobileOrTablet} = useDevice()
</script>